import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/concerns.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Top 3 concerns about parcel forwarding answered
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 7, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Virtual mailbox" className={styles.titleImage} />
                                    </div>


                                    <p>We’ve all been in this situation at one point: you hear of a unique product that catches your attention. Maybe it’s limited merchandise from a television show or a unique gadget that’s being sold in limited quantities. Maybe it’s a rare piece of equipment that you need to complete a project. However, the main problem is that the seller doesn’t ship out to your home country. So what do you do to get your coveted item? This is where mail and parcel forwarding companies come in.</p>



                                    <p>As one of the top mail redirection services in Australia, we guarantee an efficient, flexible, and trustworthy service. To this end, we make it a point to educate customers about the service, its potential risks, and how we, as a company, strive to overcome this.</p>



                                    <p>In this article, we will be answering three of the most common questions people have about mail forwarding and parcel delivery.</p>



                                    <ol type="1"><li><strong>How does mail or parcel forwarding work?</strong></li></ol>



                                    <p>Mail forwarding companies are third-party service providers who receive your package in their location, and then re-ships the same package to you for a fee. If you use a reliable company, your parcel will arrive at your door safely. However, due to unscrupulous actors in the industry, many have had less than ideal experiences.</p>



                                    <ol type="1" start="2"><li><strong>What are the risks associated with parcel forwarding?</strong></li></ol>



                                    <p>Mail and parcel forwarding companies are essentially middlemen service providers. As many will tell you, using middlemen can be risky. As a seller or customer, you run the risk of encountering scams and thieves who will tamper with your package before sending—or, in some cases, not send the packages at all!</p>



                                    <p>When you do this online, the risks with parcel forwarding are even bigger. This is because it’s relatively easy to make a convincing website and trick anyone into trusting their services. In this case, it’s typically difficult to determine a provider’s legitimacy, given that even public reviews can be falsified.</p>



                                    <p>The risks aren’t exclusive to shoppers and sellers as well. There have been many documented cases of independent re-shippers themselves ending up as unknowing couriers for credit card scams, money laundering, and other illegal activities.</p>



                                    <ol type="1" start="3"><li><strong>How do I minimise these risks associated with parcel forwarding?</strong></li></ol>



                                    <p>One way to minimise the chance of being scammed as an independent re-shipper is to use online payment schemes, like PayPal, which let you know the identity of the people involved in the transaction. This way, there are fewer chances of people hiding behind fake identities and using you as an unwitting crime accomplice.</p>



                                    <p>As a shopper or seller, here are your options:</p>



                                    <ol type="1"><li>If you’re using a parcel forwarding service for the first time, don’t trust it with an expensive item yet. Test the forwarder’s reliability with items of smaller value for a few times before letting them handle your big package.</li></ol>



                                    <ol type="1" start="2"><li>Take advantage of <a href="https://www.paypal.com/ph/webapps/mpp/paypal-buyer-protection">Paypal’s Buyer Protection </a>or any similar service. This way if something is wrong with your package, you can get a full refund.</li></ol>



                                    <ol type="1" start="3"><li>Be wary of forwarders who offer rock-bottom parcel forwarding costs. If it sounds too good to be true, it probably is.</li></ol>



                                    <p><strong>Conclusion</strong></p>



                                    <p>Parcel forwarding is a convenient way to ship or receive packages that otherwise would have been out of your reach. While online scams are quite common, there are numerous legitimate mail and parcel forwarding businesses, like HotSnail, that are passionate about helping people. To avoid falling prey to online scams and receive your package with its integrity intact, only trust companies with a proven track record in parcel forwarding and delivery.</p>



                                    <p>HotSnail is Australia’s leading <a href="http://hotsnail.com.au/">mail forwarding company</a>. We offer affordable pricing plans and 24/7 access to your mail from anywhere around the world. Contact us today to know more.</p>


                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Concerns</BadgePost>
                                    <BadgePost>Parcel forwarding</BadgePost>
                                    <BadgePost>Mail Scanning</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
